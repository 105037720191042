
  import { defineComponent, onMounted } from "vue";
  import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
  import { ErrorMessage, Field, Form } from "vee-validate";
  import * as Yup from "yup";
  import { useI18n } from "vue-i18n";
  
  export default defineComponent({
    name: "update-chatGpt",
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    setup() {
      const { t } = useI18n();
  
      //Create form validation object
      const updateChatGptForm = Yup.object().shape({
        type: Yup.string()
        .required(t("validators_error.required"))
        .label("Type"),
        apiKey: Yup.string()
        .required(t("validators_error.required"))
        .label("ApiKey"),
      });
  
      onMounted(() => {
        setCurrentPageTitle(t("ogza.chatGptSettings.title"));
      });
  
      return {
        updateChatGptForm,
      };
    },
  });
  